import React, { createContext, useContext, useState } from "react";
import classNames from "classnames";
import { Spinner, useConfig } from "components/ui";

const DEFAULT_LOADING_STATE = {
  children: null,
  visible: false,
};

export const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};
const LoadingSpinner = ({ visible, spinnerClass }) => {
  const { themeColor } = useConfig();
  return (
    <div
      className="fixed block w-full h-full top-0 right-0 left-0 bottom-0 z-50"
      style={{
        backgroundColor: "rgba(243, 243, 243, 0.4)",
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? 1 : 0,
      }}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 m-auto">
        <Spinner
          className={classNames(
            "absolute top-0 right-0 bottom-0 left-0 m-auto",
            spinnerClass
          )}
          color={`${themeColor}-400`}
          size={40}
        />
      </div>
    </div>
  );
};

const useBaseLoading = () => {
  const [loadingState, setLoadingState] = useState(DEFAULT_LOADING_STATE);

  const showLoadingSpinner = () => {
    setLoadingState({
      ...loadingState,
      visible: true,
    });
  };

  const hideLoadingSpinner = () => {
    setLoadingState({
      ...loadingState,
      visible: false,
    });
  };

  return {
    loadingState,
    showLoadingSpinner,
    hideLoadingSpinner,
  };
};

const GlobalLoadingProvider = ({ children }) => {
  const loading = useBaseLoading();

  return (
    <LoadingContext.Provider value={loading}>
      {children}
      <LoadingSpinner {...loading.loadingState} />
    </LoadingContext.Provider>
  );
};

export default GlobalLoadingProvider;
